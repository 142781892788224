import {useState} from 'react'
import styled from 'styled-components'
import {NavLink} from 'react-router-dom'

const Logo = styled.div `
    img{
        width:100%;
    }
    width:160px;
    flex:0 0 160px;
    font-size:1.8rem;
    color:#fff;
    font-weight:900;
`

const Menu = styled.ul `
    margin:0;
    padding:0;
    list-style:none;
    flex:0 1 auto;
    li{
        float:left;
        a{
            transition: 0.2s;
            font-size:1.2rem;
            display:block;
            padding:10px;
            margin:-7px 0 0 0;
            color:#fff;
            &.active{
                background:#cab887;
                border-radius: 5px;
                color:#4b3611;
            }
        }
    }

`

const Header = styled.div `
    border-left: solid 30px #cab887;
    height: 56px;
    box-sizing:border-box;
    padding:15px 20px;
    display:flex;
    justify-content: space-between;
    border-bottom:solid 1px #111;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    z-index:10;
    background:url(${require('Asset/img/header_bg.png').default});
    backdrop-filter:blur(3px);
    @media (max-width:800px) {
        display: block;
        border:none;
        padding: 0;
        text-align: center;
        ${Logo}{
            flex:none;
            margin:0 auto;
            padding:10px 0 10px 0;
            img{
                width:120px;
            }
        }
        ${Menu}{
            display: flex;
            background:#000;
            padding: 0;
            li{
                flex:0 1 20%;
                float: none;
                overflow: hidden;
                a{
                    padding:18px 0 10px 0;
                    font-size: 1.1rem;
                }
            }
        }

    }
  
`

export default function ComponentHeader({HeaderHeight}){
    const [hideLogo, setHideLogo] = useState(false)
    return(
        <Header height={HeaderHeight}>
            <Logo>
                {hideLogo ? (
                    <>KJDJ</>
                ):(
                    <img src={require('Asset/img/logo_1.png').default} alt="" />
                )}                
            </Logo>
            <Menu className="engFont">
                <li><NavLink exact to={'/'}>KUKJEDANJO</NavLink></li>
                <li><NavLink to={'/about'}>ABOUT</NavLink></li>
                <li><NavLink to={'/product'}>PRODUCT</NavLink></li>
                <li><NavLink to={'/news'}>NEWS</NavLink></li>
                <li><NavLink to={'/contact'}>CONTACT</NavLink></li>
            </Menu>
        </Header>
    )

}