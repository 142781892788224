import styled from "styled-components"
import { Tween } from 'react-gsap';


const Wrap = styled.div ` 
display: flex;
margin: 0 -30px;
article{
    flex:0 1 auto;
    padding:30px;
}
`



const ArticleHeader = styled.div ` 
padding: 160px 50px 30px 50px;
@media (max-width:800px) {
            padding-left: 20px;
            padding-right:20px;
        }
background:#111 url(${props=>props.img}) fixed center top;
background-size: cover;
overflow: hidden;
`

const ArticleBody = styled.div `
padding:20px 50px;
@media (max-width:800px) {
            padding-left: 20px;
            padding-right:20px;
        }
display: flex;
flex-wrap: wrap;
align-items: flex-start;
img.cover{
    max-width: 80%;
}
${props=> `
    ${props.center && `text-align:center; padding-top:50px; padding-bottom:50px;`}
   
`}
`

const Form = styled.div ` 
    flex:0 1 500px;
    
    box-shadow:0 15px 20px rgba(0,0,0,0.2);
    box-sizing:border-box;
    padding:20px;
    background:#15181f;
    h3{
        color:#cab887;
        font-size:1.7rem;
        margin:0;
    }
    .inputWrap{
        display: flex;
        align-items: center;
        border-bottom: solid 1px #cab887;
        margin:3px 0;
    }
    .areaWrap{
        margin:15px 0 0 0;
        box-sizing:border-box;
    }
    label{
        flex:1 0 auto;
        color:#cab887;
        display: block;
    }
    input,textarea{
        display: block;
        box-sizing:border-box;
        padding:10px;
        color:#fff;
        font-size:1.3rem;
        width: 100%;
        background:transparent;
        border:none;
        outline: none;

        
    }
    textarea{
        overflow: visible;
        height: 180px;
        border:solid 1px #cab887;
        margin:10px 0 0 0;
        box-sizing:border-box;
        width:100%;
        resize: none;
        font-size:1.1rem;
    }
    button{
        background:#cab887;
        border:none;
        cursor: pointer;
        width: 100%;
        color: #444;
        padding:15px 20px;
        display: block;
        box-sizing:border-box;
        margin:10px 0 0 0;
        transition: 0.3s;
        &:hover{
            color: #000000;
           background: #ffa70e;
        }

    }
`

const Location = styled.div `
    flex:1 1 auto;
    margin:0 0 0 30px;
    @media (max-width:800px) {
        margin:0 0 0 0;
        }
    h3{
        color:#cab887;
        font-size:1.7rem;
        margin:25px 0 15px 0;
    }

    dl{
        color:#fff;
        display: flex;
        align-items: center;
        margin:10px 0; padding:0;
        dt{
            font-size:1.4rem;
            color:#fff;
            flex:0 0 auto;
        }
        dd{
            margin: 0 0 0 10px;
            flex:0 1 auto;
            color:#888;
        }
    }
`

const Map = styled.iframe `
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;

`
const MapWrap = styled.div `
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 */
  position: relative;
`

export default function PageContact(){

    return(
        <>
         <Tween from={{ scale: 1.2, opacity:0 }} stagger={0.2} duration={2} >
                <ArticleHeader img={require('Asset/img/6.jpg').default}>
                <Tween from={{ y: '100px', opacity:0 }} stagger={0.2} duration={2} >
                <header>
                <h1>우리와 만나는 방법</h1>
                </header>
                <article>
                <h2>연락하기</h2>
                <p>(주)국제단조는 여러분의 일상속에 항상 함께 있습니다</p>
                </article>
                </Tween>
                </ArticleHeader>
         </Tween>


         <ArticleBody>
            
            <Form>
                <h3>1:1문의하기</h3>
                <div className="inputWrap">
                <label htmlFor="xName">이름</label>
                <input type="text" id="xName" name="xName" placeholder="이름을 입력해주세요"/>
                </div>

                <div className="inputWrap">
                <label htmlFor="xTel">연락가능 연락처</label>
                <input type="text" id="xTel" name="xTel" placeholder="연락처를 입력해주세요"/>
                </div>

                <div className="areaWrap">
                <label htmlFor="xTel">문의내용</label>
                <textarea></textarea>
                </div>

                <button>문의하기</button>
            </Form>


            <Location>
            <h3>찾아오시는길</h3>
            <MapWrap>
            <Map
            loading="lazy"
            allowfullscreen
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC-btNECVuNDAyOAm0IBQJX7M2w1xbLXOs
                &amp;q=경상북도+경산시+남산면+설총로+17-3">
            </Map>
            </MapWrap>
            <h3>본사</h3>
            <dl>
                <dt>본사 소재지</dt>
                <dd>경북 경산시 남산면 설총로 17-3</dd>
            </dl>

            <dl>
                <dt>대표전화</dt>
                <dd>053-854-8700 (FAX)053-854-8706</dd>
            </dl>

            <dl>
                <dt>E-mail </dt>
                <dd>3kukjedanjo@naver.com</dd>
            </dl>

            <h3>서울,경기영업소</h3>
            
            <dl>
                <dt> 미래상사</dt>
                <dd>(경기 고양시 덕양구 통일로 414번길 65 / 031-969-5950 / 010-5266-9443)</dd>
            </dl>

            <h3>강원영업소</h3>
            <dl>
            <dt>두원상사 </dt>
            <dd>(강원 원주시 소초면 치악로 2353 나동 / 010-7119-5740)</dd>
            </dl>

            <h3>충남북영업소</h3>
            <dl>
            <dt>국단상사 </dt>
            <dd>(대전 대덕구 중리로 26번길 7-11 / 042-624-1882, 010-2419-2007)</dd>
            </dl>

            <h3>경남북영업소</h3>
            <dl>
            <dt>경남북영업소</dt>
            <dd>(대구 달성군 화원읍 설화안길 27 / 010-2589-0791)</dd>
            </dl>

            <h3>호남영업소</h3>
            <dl>
            <dt>전북(대농상사)</dt>
            <dd>(전북 전주시 덕진구 동부대로 713 / 063-246-8988, 010-2682-9682)</dd>
            </dl>

            <dl>
            <dt>전남(유신볼트)</dt>
            <dd>(광주 서구 매월2로 53 산업용재유통센터 13동 101호 / 062-603-3020, 010-6848-3022)</dd>
            </dl>

            <h3>제주영업소</h3>
            <dl>
            <dt>그린기계</dt>
            <dd>(제주시 오도 2길 15 / 064-712-1814, 011-698-4477)</dd>
            </dl>


            </Location>


         </ArticleBody>

        


        </>
    )
}