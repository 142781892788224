import styled from 'styled-components'
import { useState, useEffect } from 'react'
import {Switch, Route, Redirect, NavLink} from 'react-router-dom'


import PageHome from 'Page/Home'
import PageAbout from 'Page/About'
import PageProduct from 'Page/Product'
import PageNews from 'Page/News'
import PageContact from 'Page/Contact'


const Content = styled.div `


`

const Wrap = styled.div `
    width:100%;
    height:100%;
    
    box-sizing:border-box;
    overflow: hidden;
    min-height: ${props=>props.windowHeight - 150}px;
`



export default function ComponentContent({HeaderHeight}){

    const [windowHeight, setWindowHeight] = useState(0)
    useEffect(()=>{
        setWindowHeight(window.innerHeight)
    },[])

    return(
        <Wrap padding={HeaderHeight} windowHeight={windowHeight}>
            <Content>
              <Switch>
                  <Route exact path={[`/`]} component={PageHome} />
                  <Route exact path={[`/about`]} component={PageAbout} />
                  <Route exact path={[`/product`,`/product/:id`,`/product/category/:id`,`/product/category/:id/:productId`]} component={PageProduct} />
                  <Route exact path={[`/news`,'/news/:id']} component={PageNews} />
                  <Route exact path={[`/contact`]} component={PageContact} />
                  <Redirect to={`/`} />
              </Switch>
            </Content>
        </Wrap>
    )
}