import styled from "styled-components"
import { Tween } from 'react-gsap';
import { BiPackage, BiSortUp, BiBadgeCheck } from 'react-icons/bi'
import HistoryItem from "Component/HistoryItem"

export default function PageAbout() {
    const Wrap = styled.div` 
        display: flex;
        flex-wrap: wrap;
        @media (max-width:800px) {
          flex-direction: column;
        }
        margin: 0 -30px;
        article{
            flex:0 1 auto;
            padding:30px;
        }
    `

    const Box = styled.div` 
        flex:1 0 auto;
        margin:30px;
        color: #aaa;
        text-align:center;
        border:solid 1px #fff6de;
        padding:20px;
        box-shadow:0 10px 20px rgba(0,0,0,0.2);
        .title{
            font-size:1.6rem;
            font-weight: 700;
            color: #fff6de;
        }
        .icon{
           text-align: center;

        }
    `

    const ArticleHeader = styled.div` 
        padding: 160px 50px 30px 50px;
        background:#111 url(${props => props.img}) fixed center top;
        background-size: cover;
        overflow: hidden;
        @media (max-width:800px) {
            padding-left: 20px;
            padding-right:20px;
        }
    `

    const ArticleBody = styled.div`
        padding:20px 50px;
        @media (max-width:800px) {
            padding-left: 20px;
            padding-right:20px;
        }
        img.cover{
            max-width: 80%;
        }
        ${props => `
            ${props.center && `text-align:center; padding-top:50px; padding-bottom:50px;`}
           
        `}
        @media (max-width:800px) {
        padding: 20px 5px;
        img.cover{
            max-width: 100%;
        }
            
        }
    `

    const History = styled.div ` 
        display: flex;
        h3{
            display: block;
            color: #ffba00;
            border-bottom: solid 2px #ffba00;
            padding-bottom: 5px;
        }
        .flex{
            flex:0 33.32%;
            margin:0 10px;
        }
        @media (max-width:800px){
           display: block;
        }
    `
    return (
        <>
            <Tween from={{ scale: 1.2, opacity: 0 }} stagger={0.2} duration={2} >
                <ArticleHeader img={require('Asset/img/1.jpg').default}>
                    <Tween from={{ y: '100px', opacity: 0 }} stagger={0.2} duration={2} >
                        <header>
                            <h1>국제단조 인사말</h1>
                        </header>
                        <article>

                            <p>국제단조는 농기계용 로터리칼날 전문생산 회사로써 1979년 창립하여 오랜 역사를 바탕으로 로타리 칼날의 국산화를 주도적으로 이끌어 국가산업발전에 일익을 담당해 왔습니다.
                                국제단조 로터리 칼날은 우수한 한국산 포스코 원자재를 사용하여 생산함으로써 타 제품에 비해 내마모성과 작업성이 우수합니다.
                                현재 약 300여종의 로터리 칼날을 생산하고 있으며, 꾸준한 기술개발을 통해 세계 어느 로터리 칼날과 견주어도 손색없는 제품 경쟁력을 갖추었습니다.
                                자동화 설비로 제품의 품질, 생산성이 뛰어나며 이를 기반으로 납기를 적시적기에 맞춰 응대할 수 있는 시스템으로 고객들에게 보답하고 있습니다. </p>
                        </article>
                    </Tween>
                </ArticleHeader>
            </Tween>

            <ArticleBody>
                <Wrap>
                    <Tween from={{ y: '100px', opacity: 0 }} stagger={0.2} duration={2} >
                        <article>
                            <h2>로타리 칼날<br />전문 생산 회사 (주)국제단조</h2>
                            <p>국제단조의 역사는 한국 농업 경제 발전과 같이 성장해 왔습니다. 우리의 원대한 꿈은 품질제일주의, 미래지향적이고 글로벌한 마인드를 바탕으로 21세기 농업산업을 이끌어가고 있습니다.
                                다년간 축적된 노하우와 최첨단 설비시스템을 바탕으로 완벽한 제품 생산을 위해 최선을 다하겠습니다.
                            </p>

                        </article>


                        <article>
                            <h2>국제단조의<br />로타리 칼날 생산 자동화</h2>
                            <p>완벽을 추구하는 기술력으로 내일을 창조하는 국제단조는 끊임없는 기술혁신을 위해 최고의 핵심 역량을 발휘합니다.
                                국제단조는 다년간 축척된 기술력과 최첨단 설비시스템으로 완벽한 제품을 탄생시키기 위해 최선의 노력을 다하고 있습니다.
                                특히 불량 제로화 운동은 규격제품생산과 품질향상을 위한 저희 국제단조의 의지입니다.
                            </p>
                        </article>
                    </Tween>
                </Wrap>


                <Wrap>

                    <Box>
                        <div className="icon"><BiPackage size={60} /></div>
                        <div className="title engFont">Production Facilities</div>
                        <p>생산시설에서 앞서갑니다.</p>
                    </Box>

                    <Box>
                        <div className="icon"><BiSortUp size={60} /></div>
                        <div className="title engFont">Production Capacity</div>
                        <p>생산량에서 앞서갑니다.</p>
                    </Box>

                    <Box>
                        <div className="icon"><BiBadgeCheck size={60} /></div>
                        <div className="title engFont">Best Quality</div>
                        <p>최고품질로 앞서갑니다.</p>
                    </Box>

                </Wrap>




            </ArticleBody>

            <ArticleHeader img={require('Asset/img/3.jpg').default}>
                <Tween from={{ y: '100px', opacity: 0 }} stagger={0.2} duration={2} >
                    <header>
                        <h1>HISTORY</h1>
                    </header>
                    <article>
                        <h2>국제단조 연혁</h2>
                        <p>SINCE 1978</p>
                    </article>
                </Tween>
            </ArticleHeader>
            <ArticleBody padding>

                <History>
  
                <div className="flex">
                <h3>2010 ~ 현재</h3>
                <HistoryItem
                    data={[
                     
                        {date:'2017. 10', title:'경산시장 이웃사랑 표창', eng:'Citation from the Mayor of Gyeongsan city'},
                        {date:'2015. 12', title:'산업통상자원부 소재,부품 전문기업 선정', eng:'Selected as a company specializing in materials and parts. (Ministry of Trade and Industry)'},

                        {date:'2015. 12', title:'경산시 무역의날 표창', eng:'Received a commendation from the mayor of Gyeongsan city on Trade Day'},

                        {date:'2015. 04', title:'(주)국제단조 제2공장 부지 확보(충북 옥천군 동이면 14,876m2)', eng:'secure site of the 2nd factory.(Okchoen-gun, Chungcheongbuk-do)'},

                        {date:'2015. 03', title:'태국 AGRI-ASIA 전시회 출품', eng:'participated in AGRI-ASIA exhibition (Thailand)'},

                        {date:'2014. 11', title:'로터리칼날 제조 장치 및 로터리 칼날 제조방법 특허 출원', eng:'Patent application for a rotavator blade manufacturing apparatus and manufacturing method for rotavator blade.'},

                        {date:'2014. 11', title:'이랑쟁기 제조장치 특허 출원', eng:'Patent application for plow manufacturing apparatus.'},
                        {date:'2014. 11', title:'밭갈이용 삽 실용신안 출원', eng:'Development of a plow shovel (Registration of Utility model)'},
                        {date: '2013.10',
                            title: '농기계 로터리용 칼날 어셈블리 실용실안등록',
                            eng: 'Rotary blade assembly for use in agricultural machines. (Utility model registration)'
                        },
                        {
                            date: '2013.09',
                            title: '농기계 로터리용 칼날 제조장치 특허등록',
                            eng: 'Apparatus for manufacturing rotary blade for use inagricultural machines.(Patene registration)'
                        },
                        { date: '2013. 01', title: '경산시 우수업체선정 경산시장 표창', eng: 'Commendation awarded from the mayor of gyeongsan' },
                     
                        { date: '2011. 06', title: '수출유망중소기업선정 ( 대구경북 중소기업청)', eng: 'Designated Promising Export Firm by The Small & Medium Business Admonostration. (Daegu- GyeingbukRegionalOffie of SMBA)' },
                        { date: '2010. 11', title: '천안 국제농기계자재 박람회 참가 (KIEMSTA 2010)', eng: 'Participated in Korea intermational agrcultural machine exhibition. (KIMMSTA2010)' },
                        { date: '2010. 02', title: '(제5회 농기계 산업의 날)지식경제부 장관 표창', eng: '(The 5th agro machine industry day)Mirister of knowledge Economy citation' },
                    ]}
                />
                </div>

                <div className="flex">
                <h3>2000 ~ 2009</h3>
                <HistoryItem
                    data={[
                        { date: '2009. 11', title: '독일 하노버 AGRI TECHNICA 박람회 참가', eng: 'Participated in -lanover AGRI TECH HNICA(GERMANY)' },
                        { date: '2008. 12', title: '농협중앙회 우수공급업체 감사패 수상', eng: 'Winning plaque of appreciation selected by National AgricuItural Cooperative Federation(NACF) as a good supplier' },
                       
                
                        { date: '2006. 08', title: '전국농업경영인대회 전시 참관', eng: 'Participated manager at the Agricultural Exhibition' },
                      
                        { date: '2006. 03', title: '수출기업화 사업 참여업체 선정', eng: 'ExportCooerdialization Busiress Support WorkCompanyChoice2005' },
                        { date: '2005. 08', title: 'APEC 중소기업 비즈니스포럼 참관', eng: 'Participated in APEC Small land Medium Enterprised Business Forum' },
                        { date: '2004. 09', title: '도, 농 상생 교류 자매결연 체결', eng: 'Metropolis Famers Signed a Mutral Exchange Sister' },
                        { date: '2003. 11', title: '우수자본재 개발 대통령 표창', eng: 'Excellent Development of Capital Goods Presidential Recognition' },
                        { date: '2003. 11', title: '서남아시아 지역 무역상담회 참관, 계약체결', eng: 'Participated in Southwest Asia Trade Consultation Inning, Contact Agreement' },
                        { date: '2003. 07', title: '유망중소기업선정-한국기계연구원', eng: 'Bluechip Medium an Small Enterprises Choice' },
                        { date: '2003. 07', title: '수출유망중소기업 선정-중소기업청 수출지원센터', eng: 'Export Bluechip Medium and Small enterprises Choice' },
                        { date: '2003. 04', title: '부품, 소재전문기업 선정-산업자원부', eng: 'Selected Parts and Materials Company-Ministry of Commerce and Energy' },
                        { date: '2002. 11', title: '서울국제농업기계박람회 참관 (SIMSTA 2002)', eng: 'Participated in SIMSTA 2002' },
                        { date: '2000. 11', title: 'IS09002인증 획득', eng: 'ISO9002 Certifications' },

                    ]}
                />
                </div>


                <div className="flex">
                <h3>1979 ~ 1999</h3>
                <HistoryItem
                    data={[
                        { date: '1999. 11', title: 'TAMAS 1999 참관', eng: 'Participated in TAMAS' },
                        { date: '1999. 06', title: '1999자동화라인 공정 특허출원', eng: 'Applied for a patent for the automation line process' },
                        { date: '1999. 02', title: 'KOEX 중소기업 수출 상담회', eng: 'Joined the KOEX Small and Medium Business Export Session' },
                        { date: '1998. 11', title: 'SIEMSTA 1998 참관', eng: 'PARTUCUOAT ED UB SIEMETA 1998' },
                        { date: '1998. 07', title: '자동화라인(유 공압 설비) )개발완료', eng: 'Completed the development of the automation line (high pressure hydraulic facility)' },
                        { date: '1998. 02', title: '무논정지기날, 휴립복토기날 개발완료', eng: 'Completed the development of the water farming stopper blade and the ridge ditect earth cover blade' },
                        { date: '1997. 06', title: '서명자 사장 취임', eng: 'SeoMyong-ja was inaugurated as the president' },
                        { date: '1997. 05', title: '주식회사 국제단조법인설립', eng: 'Established KukjeDanjo Corporation' },
                        { date: '1994. 09', title: '제2공장 준공, 소형 자동화라인 설비', eng: 'Built the second plant and a small scale automation line facility' },
                        { date: '1994. 05', title: '한국산업규격 KS표시허가 획득 (제 10968호)', eng: 'Acquired the Ks mark approval of the Korean Industrial Standards (No. 10968)' },
                        { date: '1993. 10', title: '제 2공장 창업승인 및 착공(대지 2,000vud)-경남 남산면 평기리', eng: 'Approved the opening of the second plant and started construction' },
                        { date: '1993. 07', title: '김용귀 사장 취임', eng: 'Kim Yong-gui was inaugurated as the president' },
                        { date: '1992. 12', title: '농협중앙회 농기계 부품구매 납품 계약', eng: 'Entered into a supply contact for the purchase of farm machine components with Nonghyup' },
                        { date: '1991. 05', title: '상공부 체결화 업체지정(대동공업) 상공부증진 제 2828-467호', eng: 'Designated as a company for connection by the Ministry of Commerce, Industry and Erergy (Daedong Co., Ltd.)' },
                        { date: '1984. 06', title: '본사이전-대구광역시 수성구 사월동 391번지', eng: 'Moved the headquarters: 91 Sawol-dong, Suseong-gu, Daegu City' },
                        { date: '1979. 02', title: '국제종합기계(주)납품', eng: 'Supplied product to Kukje Machinery Co.,Ltd.' },
                        { date: '1979. 01', title: '국제단조 설립-부산광역시 북구 감전1동 505-3번지', eng: 'Established KukjeDanjo:505-3, Gamjeon 1 dong, Buk-gu, Busan City' },
                    ]}
                />
                </div>
                </History>
                
            </ArticleBody>


        </>
    )
}