import styled from "styled-components"

const List = styled.ul ` 
    list-style: none;
    margin:0;
    color:#fff;
    text-align: left;
    
    li{
        display: flex;
        margin-bottom: 15px;
        line-height: 1.4rem;
        span{
            display: block;
            flex:1 0 90px;
            font-weight: 700;
        }
        .history{
            flex:1 1 auto;
            width: 100%;
            .title{
                font-size:1.3rem;
                font-weight: 100;
                line-height: 1.8rem;
            }
            .eng{
                font-size:0.9rem;
                color:#888;
                margin:3px 0 0 0;
            }
        }
    }
`

export default function HistoryItem({data}) {
    return (
        <>
            <List>
               {data.map((val,key)=>
                <li key={key}>
                <span>{val.date}</span>
                <div className="history">
                   <div className="title">{val.title}</div>
                   <div className="eng">{val.eng}</div>
                </div>
                </li>
               )}
            </List>
        </>

    )
}