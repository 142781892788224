import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Tween } from 'react-gsap';

const VideoWrap = styled.div `
  position: fixed;
  video {
  object-fit: cover;
  transform: scale(1.2);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index:-1;
}
`

const Wrap = styled.div ` 
    position: relative;
    padding:50px 50px 50px 50px;
    article{
        position: relative;
        z-index: 2;
        max-width: 55%;
        border-left:solid 1px #fff; 
        padding: 110px 0 0 30px;
        @media (max-width:800px){
            max-width: 90%;
        }
    }
    height: 100%;
    .link{
        border: solid 2px #ccc;
        color: #ccc;
        padding:10px 15px;
        display: inline-block;
        border-radius: 2px;
        &:hover{
            color: #fff;
            border-color: #fff;
        }
    }   
`

const Cover = styled.div ` 
        left: 0;
        top:0;
        position: fixed;
        background: url(${require('Asset/img/bgf.png').default});
        width: 100%;
        height: 100%;
        z-index:1;
`

export default function PageAbout(){
    return(
        <Wrap>
                <Cover />
                <Tween from={{ y: '-300px', opacity:0 }} stagger={0.2} duration={2} >
                <article>
                <Tween from={{ y: '200px',opacity:0 }} stagger={0.2}  duration={2}>
                <h2>세상에서<br/>가장 가치있는 것을<br/>만들어냅니다.</h2>
                <p>국제단조는 농기계용 로터리칼날 전문생산 회사로써 1979년 창립하여 오랜 역사를 바탕으로 로타리 칼날의 국산화를 주도적으로 이끌어 국가산업발전에 일익을 담당해 왔습니다.
국제단조 로터리 칼날은 우수한 한국산 포스코 원자재를 사용하여 생산함으로써 타 제품에 비해 내마모성과 작업성이 우수합니다.
현재 약 300여종의 로터리 칼날을 생산하고 있으며, 꾸준한 기술개발을 통해 세계 어느 로터리 칼날과 견주어도 손색없는 제품 경쟁력을 갖추었습니다.
 자동화 설비로 제품의 품질, 생산성이 뛰어나며 이를 기반으로 납기를 적시적기에 맞춰 응대할 수 있는 시스템으로 고객들에게 보답하고 있습니다. </p>
                <Link to="/" className="link">제품소개 바로보기</Link>
                </Tween>
                </article>
                </Tween>
                <VideoWrap>
                <video autoPlay playsInline muted loop>
                <source src={require('Asset/djbg.mp4').default} type="video/mp4" />
                </video>
                </VideoWrap>
            
        </Wrap>
    )
}