import styled from "styled-components"
import { Tween } from 'react-gsap';
import { Link,Route,useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import remarkImgLinks from "@pondorasti/remark-img-links";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

const ArticleHeader = styled.div ` 
padding: 160px 50px 30px 50px;
background:#111 url(${props=>props.img}) fixed center top;
background-size: cover;
overflow: hidden;
@media (max-width:800px) {
            padding-left: 20px;
            padding-right:20px;
        }
`

const ArticleBody = styled.div `
padding:20px 50px;
@media (max-width:800px) {
            padding-left: 20px;
            padding-right:20px;
        }
display: flex;
img.cover{
    max-width: 80%;
}
${props=> `
    ${props.center && `text-align:center; padding-top:50px; padding-bottom:50px;`}
`}
`

const SelectBox = styled.select `
appearance: none;
    display: block;
    flex:1 1 auto;
    margin:3px 3px;
    box-sizing:border-box;
    background:#0d0e13;
    border:none;
    color:#fff;
    width:100%;
    padding:15px;
    color:#8fa3d4;
    cursor: pointer;
    border:solid 1px #616e8e;
    &:hover{
        background:#181d29;
    }
`

const List = styled.ul ` 
    list-style:none;
    margin:0;
    padding:20px 50px;
    @media (max-width:800px) {
            padding-left: 20px;
            padding-right:20px;
        }
    a{
        display: flex;
        box-sizing:border-box;
        align-items: center;
        margin:4px 0;
        font-size:1.2rem;
        background:#1a1d25;
        transition: 0.2s;
        &:hover{
            border-left:solid 5px #fbcf83;
            color:#fbcf83;
        }
        .flex{
            flex: 1 0 auto;
            @media (max-width:800px){
                font-size:1rem;
            }
            padding:15px;
            &.category{
                flex:0 0 90px;
                text-align: center;
                background:#13151b;
                color:#677292;
                @media (max-width:800px){
                    flex:0 0 50px;
                }
            }
            &.date{
                flex:1 1 150px;
                text-align: center;
                background:#1a1d25;
                text-align: right;
                color:#777;
            }
            &.title{
                flex:1 0 auto;
                background:#1a1d25;
            }
        }
    }
`

const ViewCover = styled.div ` 
    position: fixed;
    left:0;
    top:0;
    background:url(${require('Asset/img/bgf.png').default});
    width: 100%;
    height: 100%;
    z-index:15;
    backdrop-filter: blur(5px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    animation: showCover 1s;
    @keyframes showCover {
        0%{opacity:0;}
    }
`

const View = styled.div `
    background:#1f232d;
    width:100%;
    max-width: 900px;
    min-height: 300px;
    margin: 30px auto;
    box-shadow:0 10px 10px rgba(0,0,0,0.4);
    border-top:solid 3px #cab887;
    animation: showView 1s;
    @keyframes showView {
        0%{opacity:0; transform:translateY(40px)}
    }
`

const ViewHeader = styled.div `
    padding:30px;
    background:#1b1e26;
    border-bottom:solid 1px #121318;
    h2{
        color:#cab887;
        font-size:1.6rem;
        margin:0;
    }
    .date{
        color:#888;
        margin:10px 0 0 0;
    }
`

const ViewArticle = styled.div ` 
    color: #fff;
    padding:30px;
    border-top:solid 1px #2b2e39;
    img{
        max-width:100%;
    }
`

const CloseBtn = styled.div ` 
    img{
        max-width: 100%;
    }
    width: 70px;
    padding:20px;   
    margin:10px auto -20px auto;
    opacity: 0.6;
    cursor: pointer;
    transition: 0.2s;
    &:hover{
        opacity: 1;
    }
`

export const ContentView = ({match}) => {

    const [content, setContent] = useState({})
    const history = useHistory()
    useEffect(async()=>{
        const APInews = await axios.get('https://kj.grf.gg/api/newses/'+match.params.id)
        setContent(APInews.data.data.attributes);

        const targetElement = document.querySelector('#cover')
        disableBodyScroll(targetElement)

    },[])

    const goBackNews = () => {
        history.push('/news')
    }



    return(
        <>
        <ViewCover id="cover">
        <CloseBtn onClick={()=>goBackNews()}>
        <img src={require('Asset/img/close.png').default} alt="닫기" />
        </CloseBtn>
        <View>
            <ViewHeader>
            <h2>{content.title}</h2>
            <div className="date">{moment(content.publishedAt).format('YYYY-MM-DD')}에 작성된 글</div>
            </ViewHeader>

            <ViewArticle>
            <ReactMarkdown children={content.content} remarkPlugins={[[remarkImgLinks, { absolutePath: "https://kj.grf.gg" }]]} />
            </ViewArticle>
        </View>
        </ViewCover>
        </>
    )
}



export default function PageNews(){

    const [news, setNews] = useState([])

    useEffect(async()=>{
       const APInews = await axios.get('https://kj.grf.gg/api/newses?sort[0]=id:DESC')
       setNews(APInews.data.data)
        

    },[])

    return(
        <>
         <Tween from={{ scale: 1.2, opacity:0 }} stagger={0.2} duration={2} >
                <ArticleHeader img={require('Asset/img/5.jpg').default}>
                <Tween from={{ y: '100px', opacity:0 }} stagger={0.2} duration={2} >
                <header>
                <h1>NEWS</h1>
                </header>
                <article>
                <h2>국제단조 소식</h2>
                <p>(주)국제단조에서 전달드립니다</p>
                </article>
                </Tween>
                </ArticleHeader>
         </Tween>

                <ArticleBody>
                    <SelectBox>
                        <option>전체소식</option>
                        <option>공지사항</option>
                        <option>새소식</option>
                        <option>영상</option>
                    </SelectBox>

                </ArticleBody>


                <List>
                <Tween from={{ y:40, opacity:0 }} stagger={0.4} duration={0.8} >
                    {news.map(val=>
                             <li>
                             <Link to={`/news/${val.id}`}>
                             <div className="flex category">공지사항</div>
                             <div className="flex title">{val.attributes.title}</div>
                             <div className="flex date">{moment(val.attributes.publishedAt).format('YYYY-MM-DD')}</div>
                             </Link>
                             </li>    
                    )}
                </Tween>
                </List>

                <Route exact path={[`/news/:id`]} component={ContentView} />


        </>
    )
}