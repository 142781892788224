import styled from 'styled-components'
import Header from 'Component/Header'
import Footer from 'Component/Footer'
import Content from 'Component/Content'


const Wrap = styled.div `

`

function App() {
  return (
    <Wrap>
      <Header HeaderHeight={90} />
      <Content HeaderHeight={90}  />
      <Footer />
    </Wrap>
  );
}

export default App;
