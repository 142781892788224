import styled from "styled-components"
import { Tween } from 'react-gsap';
import axios from 'axios'
import {useState, useEffect} from 'react'
import { NavLink, Route, useHistory, useParams, useRouteMatch } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import remarkImgLinks from "@pondorasti/remark-img-links";

const Wrap = styled.div ` 
display: flex;
margin: 0 -30px;
article{
    flex:0 1 auto;
    padding:30px;
}
`

const Box = styled.div ` 
flex:1 0 auto;
margin:30px;
color: #aaa;
text-align:center;
border:solid 1px #fff6de;
padding:20px;
box-shadow:0 10px 20px rgba(0,0,0,0.2);
.title{
    font-size:1.6rem;
    font-weight: 700;
    color: #fff6de;
}
.icon{
   text-align: center;

}
`

const ArticleHeader = styled.div ` 
padding: 160px 50px 30px 50px;
background:#111 url(${props=>props.img}) fixed center top;
background-size: cover;
overflow: hidden;
@media (max-width:800px) {
            padding-left: 20px;
            padding-right:20px;
        }
`

const ArticleBody = styled.div `
padding:20px 50px;
@media (max-width:800px) {
            padding-left: 20px;
            padding-right:20px;
        }
display: flex;
img.cover{
    max-width: 80%;
}
${props=> `
    ${props.center && `text-align:center; padding-top:50px; padding-bottom:50px;`}
`}
`

const SelectBox = styled.select `
    appearance: none;
    display: block;
    flex:1 1 auto;
    margin:3px 3px;
    box-sizing:border-box;
    background:#0d0e13;
    border:none;
    color:#fff;
    width:100%;
    padding:15px;
    color:#8fa3d4;
    cursor: pointer;
    border:solid 1px #616e8e;
    &:hover{
        background:#181d29;
    }
`

const ProductList = styled.ul ` 
    display: flex;
    
 
    @media (max-width:800px) {
        flex-direction: column
    }
    @media (max-width:800px) {
            padding-left: 20px;
            padding-right:20px;
        }
    list-style:none;
    margin:0;
    padding:10px 50px;
    li{
        flex:0 1 20%;
        box-sizing:border-box;
    }
    li.none{
        color:#fff;
        text-align: center;
        font-size: 1.2rem;
        padding:30px 10px;
        flex:1 100%;
        background: #272c39;
        
    }
    .item{
        background:#15181f;
        margin:5px;
        position:relative;
        img{
            width:100%;
        }
    }
    .data{
        position: absolute;
        z-index: 2;
        left:0;
        bottom:0;
        width:100%;
        padding:20px;
        color:#fff;
        background:#15181f;
        box-sizing:border-box;
        .model{
            color:#cab887;
            margin:0 0 5px 0;
        }
        .name{
            font-size:1.2rem;
        }
    }
`

const ViewCover = styled.div ` 
    position: fixed;
    left:0;
    top:0;
    background:url(${require('Asset/img/bgf.png').default});
    width: 100%;
    height: 100%;
    z-index:15;
    backdrop-filter: blur(5px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    animation: showCover 1s;
    @keyframes showCover {
        0%{opacity:0;}
    }
`


const View = styled.div `
    background:#1f232d;
    width:100%;
    max-width: 600px;
    min-height: 300px;
    margin: 30px auto;
    box-shadow:0 10px 10px rgba(0,0,0,0.4);
    border-top:solid 3px #cab887;
    animation: showView 1s;

    color:#fff;
    @keyframes showView {
        0%{opacity:0; transform:translateY(40px)}
    }

    h2{
        small{
            font-weight: 100;
            display: block;
            border-bottom: dashed 1px #444;
            padding:10px 0 20px 0;
            color:#888;
            margin:0 0 20px 0;
        }
    }

    .imageWrap{
        text-align: center;
        background: #000 center center no-repeat;
        background-size:cover;
        position: relative;
        padding:30px;
        box-shadow:0 5px 20px rgba(0,0,0,0.4);
        overflow: hidden;

        img{
            max-width: 300px;
            position: relative;
            z-index: 3;
            border-radius: 4px;
            box-shadow:0 5px 20px rgba(0,0,0,0.4);

        }
        &:after{
            content: "";
            position: absolute;
            width:100%;
            height: 100%;
            backdrop-filter: blur(30px) brightness(30%);
            left:0;
            top:0;
        }
    }
    .body{
        padding:30px;
    }
    h3{
        font-weight: 400;
        color:#cab887;
    }
    article{
        font-weight: 100;
        line-height: 1.8rem;
        color:#aaa;
    }
`


const CloseBtn = styled.div ` 
    img{
        max-width: 100%;
    }
    width: 70px;
    padding:20px;   
    margin:10px auto -20px auto;
    opacity: 0.6;
    cursor: pointer;
    transition: 0.2s;
    &:hover{
        opacity: 1;
    }
`


const CategoryList = styled.ul ` 
display: flex;
flex-wrap: wrap;
 
    @media (max-width:800px) {
        flex-direction: column
    }
    @media (max-width:800px) {
            padding-left: 20px;
            padding-right:20px;
        }
    list-style:none;
    margin:0;
    padding:10px 50px;
    li{
        flex:0 1 20%;
        box-sizing:border-box;
    }
    .item{
        background:#15181f;
        margin:2px;
        position:relative;
        transition: 0.2s;
        cursor: pointer;
        img{
            width:100%;
        }
        &:hover{
            border:solid 1px #fff;
            transform:scale(1.05);
            position: relative;
            z-index:2;
        }
    }

    .name{
        color:#fff;
        font-size:1.4rem;
        padding:20px 0;
        text-align: center;
    }
`

const CategoryTitle = styled.div ` 
    font-size: 2.2rem;
    font-weight: 100;
    color:#fff;
    padding:40px 50px 10px 50px;
    @media (max-width:800px) {
            padding-left: 20px;
            padding-right:20px;
        }
    button{
        display: inline-block;
        font-size:1rem;
        background: #222;
        border:solid 1px #fff;
        color:#fff;
        padding:10px 20px;
        margin:0 0 0 10px;
        vertical-align: 5px;
        border-radius: 5px;
    }
`


export function ProductView() {

    const [gearData, setGearData] = useState({})
    const history = useHistory() 
    const params = useParams()

    useEffect(()=>{
        (async function(){
            const gear = await axios.get(`https://kj.grf.gg/api/gears/${params.productId}?populate=*`)
            setGearData(gear.data.data.attributes)
          })()
    },[])



    const goBackNews = () => {
        history.goBack()
    }


    if(!gearData.gear_category) return (<></>)
    
        return (
            <ViewCover>
                    <CloseBtn onClick={()=>goBackNews()}>
                    <img src={require('Asset/img/close.png').default} alt="닫기" />
                    </CloseBtn>
                <View>
                    <div className="imageWrap" style={{backgroundImage:`url(https://kj.grf.gg${gearData.image.data.attributes.url})`}}>
                    <img src={`https://kj.grf.gg${gearData.image.data.attributes.url}`} alt="" />
                    </div>
                    <div className="body">
                    <h2>{gearData.product_name} <small>{gearData.gear_category.data.attributes.name}</small></h2>

                    <h3>제품 설명</h3>
                    <article>
                    <ReactMarkdown children={gearData.description} remarkPlugins={[[remarkImgLinks, { absolutePath: "https://kj.grf.gg" }]]} />
                   
                    </article>
                    </div>
                </View>
            </ViewCover>
        )
    

}


export default function PageProduct(){

    const [list, setList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [category, setCategory] = useState('')
    const [categoryTitle, setCategoryTitle] = useState('')
    const router = useRouteMatch()
    const params = useParams()
    const history = useHistory() 


    
    useEffect(()=>{
        (async function(){
          const gear = await axios.get('https://kj.grf.gg/api/gear-categories?populate=*')
          setCategoryList(gear.data.data)
        })()
    },[])

    useEffect(()=>{
        (async function(){
            const gear = await axios.get(`https://kj.grf.gg/api/gears/?populate=*&filters[gear_category][id][$eq]=${params.id}`)
            setList(gear.data.data)
            setCategory(params.id)


        })()
    },[params])

    useEffect(()=>{
        if(params.id){
        (async function(){
            const gear = await axios.get('https://kj.grf.gg/api/gear-categories?populate=*')
            const categoryData = gear.data.data.filter(s=>s.id === Number(params.id))[0]
            setCategoryTitle(categoryData.attributes.name)
        })()
         }
    },[params.id])

    
    const goBack = () =>{
        history.goBack()
    }
     

    return(
        <>
         <Tween from={{ scale: 1.2, opacity:0 }} stagger={0.2} duration={2} >
                <ArticleHeader img={require('Asset/img/4.jpg').default}>
                <Tween from={{ y: '100px', opacity:0 }} stagger={0.2} duration={2} >
                <header>
                <h1>PRODUCT LIST</h1>
                </header>
                <article>
                <h2>제품안내</h2>
                <p>(주)국제단조에서 취급하는 각 제품들을 소개합니다</p>
                </article>
                </Tween>
                </ArticleHeader>
         </Tween>

                {(router.path !== '/product/category/:id') && (router.path !== '/product/category/:id/:productId') && 
                    <CategoryList>
                    {categoryList.map(val=>
                    <>
                        <li>
                        <NavLink to={`/product/category/${val.id}`}>
                        <div className="item">
                        <div className="img"><img src={`https://kj.grf.gg${val.attributes.image.data.attributes.url}`} alt="" /></div>
                        <div className="name">{val.attributes.name}</div>
                        </div>
                        </NavLink>    
                        </li>
                    </>      
                    )}
                    </CategoryList>
                }    

       

                <Route exact path={[`/product/category/:id`,`/product/category/:id/:productId`]} component={()=>{
                    return(
                        <>
                    
                        <CategoryTitle>
                        {categoryTitle}
                        <button onClick={()=>goBack()}>제품 카테고리로 돌아가기</button>
                        </CategoryTitle>
        
                        <ProductList>
                        {list.length === 0 && 
                        <li className="none">아직 등록된 제품이 없습니다. 잠시만 기다려주세요.</li>
                        }
                        {list.map(val=>
                        <>
                             <li>
                             <NavLink to={`/product/category/${category}/${val.id}`}>
                                <div className="item">
                                    <div className="img"><img src={`https://kj.grf.gg${val.attributes.image.data.attributes.url}`} alt="" /></div>
                                    <div className="data">
                                    <div className="model engFont">{val.attributes.gear_category.data.attributes.name}</div>
                                    <div className="name">{val.attributes.product_name}</div>
                                    </div>
                                </div>
                            </NavLink>
                            </li>     
                        </>
                        )}        
                        </ProductList>
                        </>
                    )
                }} />

                <Route exact path={`/product/category/:id/:productId`} component={ProductView} />


                        

        </>
    )
}