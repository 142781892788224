
import styled from "styled-components"

const Wrap = styled.div ` 
   
    position: relative;
    z-index:2;
    color:#999;
    margin: 30px 50px;
    @media (max-width:800px) {
            margin-left: 20px;
            margin-right:20px;
        }
    box-sizing: border-box;
    padding:20px 0;
    border-top:solid 1px #444;
    p{
        color:#777;
        margin: 5px 0 0 0;
    }
    .logo{
        margin:0 0 10px 0;
        img{
            max-width: 120px;
        }
    }
`

export const Footer = () => {
    return(
        <Wrap>
            <div className="logo"><img src={require('Asset/img/logo_v.png').default} alt="" /></div>
            2021 KUKJEDANJO AllRights Reserved.
            <p>
              경북 경산시 남산면 설총로 17-3
              대표전화 053-854-8700 팩스 053-854-8706
              E-mail : kukjedanjo@naver.com
            </p>
        </Wrap> 
    )
}

export default Footer